

/* import any special fonts */

:root{
    /* these will be the default theme colors */
    --sokol-blue:#03A596;
    --sokol-dark-blue:#012E41;
    --sokol-orange:#F28705;
}

body{
    /* set font */
    font-family: 'Lato', Arial, sans-serif;
    font-size:1.2rem !important;
}

.header{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center !important;
}

.header h1{
    text-transform: uppercase;
    color:var(--sokol-orange);
}

h2{
    font-weight:400 !important;
}

.header h1 span{
    color:var(--sokol-blue);
}

.headline-background{
    background-color:var(--sokol-blue);
    z-index:1;
}

.headline-background .headline-text{
    /* max-width:1320px; */
    z-index:1;
}

.headline-text{
    max-width:1320px;
}

.headline-text hr{
    max-width:100px;
    margin-top:0;
}

.headline-text .gray-line{
    max-width:100px;
    margin-top:0;
}

.headline-text h2{
    padding-bottom:15px;
}

.headlineWithIcons-icons{
    max-width: 1320px;
    z-index: 1;
}

.icon-separator{
    margin-bottom:200px;
}

.headlineWithIcons-icons.page{
    top: -135px;
}


.headlineWithIcons-icons ul li{
    /*height:237px; */ 
    height: 216px;
}

.bg-white{
    background-color:#fff;
}

.bg-black{
    background-color:#000;
}

.bg-orange{
    background-color:var(--sokol-orange);
}

.bg-blue{
    background-color:var(--sokol-blue);
}

.bg-dark-blue{
    background-color:var(--sokol-dark-blue);
}

.card{
    border-radius: 0 !important;
    max-width:500px;
}

.card-border{ border:1px solid #fff; }

.card.backgroundColor-sokol-dark-blue{
    background-color:var(--sokol-dark-blue);
    color:#fff;
}

.card.backgroundColor-sokol-blue{
    background-color:var(--sokol-blue);
    color:#fff;
}

.btn.btn-cta{
    background-color:transparent;
    border:1px solid #fff;
    border-radius:0;
    transition: ease-in-out .5s;
}

.btn.btn-cta .text-white:hover{
    color:var(--sokol-dark-blue);;
    border-color:var(--sokol-dark-blue);
    background-color:#fff;

}

.cta-orange{
    color:var(--sokol-orange) !important;
    border-color:var(--sokol-orange) !important;
}

.cta-orange:hover{
    color:#fff !important;
    background-color:var(--sokol-orange);
}

.cta-blue{
    color:var(--sokol-blue) !important;
    border-color:var(--sokol-blue) !important;
   
}

.cta-blue:hover{
    color:#fff !important;
    background-color:var(--sokol-blue);
    
}

.cta-dark-blue{
    color:var(--sokol-dark-blue) !important;
    border-color:var(--sokol-dark-blue) !important;
    
}

.cta-dark-blue:hover{
    color:#fff !important;
    border-color:var(--sokol-dark-blue);
    background-color:var(--sokol-dark-blue);
    
}

.cta-white{
    color:#fff !important;
    border-color:#fff !important;
}

.cta-white:hover {
    color:var(--sokol-dark-blue) !important;
    border-color: #FFF;
    background-color: #FFF;
}

.cta-black{
    color:#000;
    border-color:#000;
}

.gray-line{
    /* background-color:#f3f3f2; */
    background-color:#000;
    opacity:.25;
    margin-top:0;
}

.white-line{
    background-color:#fff;
    opacity:.9;
    margin-top:0;
}

.text-color-blue{
    color:var(--sokol-blue);
}

.text-color-dark-blue{
    color:var(--sokol-dark-blue);
}

.text-color-orange{
    color:var(--sokol-orange);
}

.text-color-black{
    color:#000;
}

.headline-text h2{
    text-transform: uppercase;
}

.imageWithCard{
    max-height:850px;
}

.bg-overlay{
	width: 100%;
    height: 400px;
    background: linear-gradient(0deg, rgba(255 255 255 / 54%), rgba(255 0 150 / 69%)), url(../../images/carousel-img2.jpg);
    background-repeat:no-repeat;
    background-size: cover;
}


.background-image-inset-border{
    width: calc(100% - 20px) !important;
    border: 1px solid #fff;
    height: calc(100% - 20px) !important    ;
    top: 10px;
    left: 10px;
    position: absolute;
}

.border-inset{
    outline: 1px solid white;
    outline-offset: -15px;
}

.circle{
    height:128px;
    width:128px !important;
    border-radius: 50%;
    background-color:var(--sokol-blue);
    z-index:-1;
}

.center-circle{
    top: -40px;
    left: calc(50% - 64px);
}

footer {
    background:var(--sokol-dark-blue);
    color:#fff;
}

footer .copyright {
    font-size:.85rem;

}


/* formie form styles */
footer .fui-form-container{
    width:50%;
} 

footer .fui-form-container .fui-input{
    border-radius:0;
}

footer .fui-form-container .fui-btn-wrapper{
    padding-top:0;
}

footer .fui-form-container .fui-btn-wrapper button{
    background:transparent;
    border-radius:0;
    border-color:var(--sokol-orange);
    color:var(--sokol-orange);
}

.fui-field:not(.fui-type-hidden-field){
    margin-bottom: 1.5rem !important;
}

[id*="subscribeForm"] .fui-btn-wrapper .fui-btn.fui-submit:hover{
    border-color:var(--sokol-orange);
    background-color:var(--sokol-orange);
    color:#fff;
    transition: ease .35s;   
}





footer .text-link{
    color:#fff;
    text-decoration:none;
    transition: ease-in-out .35s
}



footer .text-link:hover{
    color:var(--sokol-blue);
}

/* form formie overrides */

[id*="contactShortForm"] .fui-btn-wrapper .fui-btn.fui-submit{
    background-color:transparent;
    border-radius:0;
    color:#fff;
    border-color:#fff;
    transition: ease .35s;
}

[id*="contactShortForm"] .fui-btn-wrapper .fui-btn.fui-submit:hover{
    border-color:var(--sokol-blue);
    background-color:#fff;
    color:var(--sokol-blue);
    transition: ease .35s;   
}

#long-form-button.fui-btn-wrapper .fui-submit{
    background-color:var(--sokol-orange) !important;
    border-radius:0 !important;
    color:#fff;
    border-color:var(--sokol-orange) !important;
    transition: ease .35s;
}

#long-form-button.fui-btn-wrapper .fui-submit:hover{
    background-color:#fff !important;
    color:var(--sokol-orange) !important;
    border-color:var(--sokol-orange) !important;
    transition: ease .35s;
}

[id*="plmaForm"] .fui-btn-wrapper .fui-btn.fui-submit{
    background-color:transparent;
    border-radius:0;
    color:#fff;
    border-color:#fff;
    transition: ease .35s;
}

[id*="plmaForm"] .fui-btn-wrapper .fui-btn.fui-submit:hover{
    border-color:var(--sokol-blue);
    background-color:#fff;
    color:var(--sokol-blue);
    transition: ease .35s;   
}


.fui-input-container input[type="text"], .fui-input-container input[type="email"], .fui-input-container textarea, .fui-input-container input[type="tel"], .fui-input-container select{
    border-radius:0;
}

#searchTerm{
    border-radius:0;
}


/* social link stuff */
.social-links li{
    text-decoration:none;
    list-style:none;
    display:inline;
}

.social-links li a{
    font-size: 32px;
    color:#fff; 
}

.fa-square-facebook{
    color:#1877f2;
}

.fa-square-twitter{
    color:#1da1f2;
}

.fa-square-youtube{
    color:#ff0000;
}

.fa-linkedin{
    color:#0a66c2;
}

/********************/



/*
    positioning card and image
*/


.position-r{right:50px;}
.position-l{left:50px;}
.position-up{top:-170px;}

.card-z-index-1{z-index:1;}
.card-z-index-2{z-index:2;}
.img-z-index-1{z-index:-1;}



/* positioning */
.headlineWithIcons-icons{
    bottom:-190px;   
}

.floating-footer-image{
    padding-top:200px;
    overflow:hidden;
}


/*
    hover overs
*/  

.icon-overlay:hover{
    background-color: var(--sokol-dark-blue);
}

.icon-overlay:hover img{
    opacity:.15;
}

.icon-overlay:hover p{
    opacity:.5;
}

/* .headlineWithIcons-icons p:hover{
    background-color: var(--sokol-dark-blue);
} */




/**
    menu css
*/

/* bootstrap menu hover stuff */
.dropdown:hover .dropdown-menu{
    display: block;
    transition: all .5s;
}

.dropdown-menu li a{
    padding-left:20px !important;
}

.navigation-wrapper{
    position:relative;
}

.navigation-wrapper > nav{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    opacity: .85;
}

.search-glass{
    width:32px;
    border: none;
    cursor: pointer;
    background-color: var(--sokol-orange);
    color:#fff;
    position:relative;
}

#searchTerm{
    background-color:rgb(204,213,217);
}

.search-glass{
    font-family: FontAwesome;
}

.navbar-nav .nav-item .nav-link{
    color:var(--sokol-blue) !important;
    transition: ease-out .25s;
    text-transform: uppercase;
    
}

#navbarNav a{
    font-weight:500;
}

#navbarNav ul li:last-child{
    margin-right:0 !important;
}

#navbarNav ul li:last-child a{
    padding-right:0;
}

.navbar-nav .nav-item .nav-link:hover{
    color:var(--sokol-orange) !important;
    transition: ease-in .25s;
}

.dropdown-menu{
    min-width: 15rem !important;
}

#topNav, #topNav .nav-link{
    background-color:var(--sokol-dark-blue);
    color:#fff !important;
}

#topNav, #topNav .nav-link:hover{
    color: var(--sokol-orange)!important;
    transition: ease-in .25s;
}

.subscribe-form{
    width:100%;
}

.bleed-image{
    overflow:hidden;
   
}





/*  mobile media queries  */

@media screen and (max-width:1280px) {
    .position-up{
        top:-100px;
    }
}

@media screen and (min-width:1232px){
    .enlarged-icon-separator{
        margin-bottom:175px !important;
    }
    .icon-separator{
        margin-bottom:130px;
    }
    .bottom-icon-separator{
        margin-bottom:300px;
    }
}

@media (min-width:993px) and (max-width:1231px){
    .enlarged-icon-separator{
        margin-bottom:425px !important;
    }
    .icon-separator{
        margin-bottom:250px;
    }
    .bottom-icon-separator{
        margin-bottom:425px;
    }
}

@media (min-width:992px){
    /* menu */
    .navbar .nav-item:hover .dropdown-menu{ display: block; }
}


@media screen and (max-width: 992px){
    .floating-footer-image{
        padding-top:0px;
    }
    
    .imageWithCard{
        max-height:950px;
    }
    
    .enlarged-icon-separator{
        margin-bottom: 0 !important;
    }
    .icon-separator{
        margin-bottom:100px;
    }
    
    .headlineWithIcons-icons{
        bottom:0px;
    }

    .headlineWithIcons-icons.position-absolute{
        position: static !important;
    }

    footer .fui-form-container{
        width:100%;
    }

    footer .fui-btn-wrapper, .fui-btn-container{
        justify-content:center;
    }

    footer .fui-btn-wrapper button.fui-submit{
        margin-bottom: 20px;
    }

    .subscribe-form{
        width:350px;
    }

}

@media screen and (max-width:810px){
    .icon-separator{
        margin-bottom:50px;
    }

    .position-up{
        top:-100px;
    }

    .position-l{
        left:0px;
    }

    .position-r{
        right:0px;
    }

    .card{
        max-width:100%;
    }
}

@media screen and (max-width:767px) {
    .imageWithCard{
        max-height:none;
    }

    .spacer{
        display: none !important;
    }
}


@media screen and (max-width:576px){
    .header{
        min-height: 800px;
    }
}

/*
    bootstrap breakpoints
    576px - sm
    768px - md
    992 - lg 
    1200px - xl
    1400px - xxl

*/

.home-header-headline-text{
    font-size:50px;
    font-weight:300;
}
.xs-75percent{
    font-size:75%;
}
.textWithProduct{
    display:none !important;
}
.productCopy{
    align-items: flex-end;
}
.mobileTextWithProduct{
    max-width: 500px;
}
.social-links{
    padding-left:0;
}
.smallIconsMinHeight{
    min-height:unset !important;
}
.largeIconsMinHeight{
    min-height:unset !important;
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .home-header-headline-text{
        font-size:60px;
    } 
    .xs-75percent{
        font-size:100%;
    }  
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .nav-link{
        font-size: 1.04rem;
    }
    .lg-90percent{
        font-size:90%;
    }
    .productCopy{
        align-items: center;
    }
    .mobileTextWithProduct{
        max-width: unset !important;
        min-height: unset !important;
    }  
    .smallIconsMinHeight{
        min-height:320px !important;
    }
    .largeIconsMinHeight{
        min-height: 390px !important;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .nav-link{
        font-size: 1.3rem;
    }
    .lg-90percent{
        font-size:100%;
    }  
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
    .rightAlignCta{
        float: right;
        margin-right: 30px;
    }
    .mobileTextWithProduct{
        display:none !important;
    }
    .textWithProduct{
        display:block!important;
    }
}





